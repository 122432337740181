module.exports = {
  siteTitle: 'Καλαϊτζάκης Ζαχαρίας, Ιατρός - Βαρίατρος, Υπ.Διδάκτωρ Γαστρεντερολογίας & Διατροφής Πανεπιστημίου Κρήτης',
  manifestName: 'Bariatriki',
  manifestShortName: 'Bariatriki', // max 12 characters
  manifestStartUrl: 'https://www.bariatriki.com',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Το Bariatriki.gr σύντομα κοντά σας!',
  subHeading:
    "Κάνε κάτι για τον εαυτό σου, για το οποίο ο μελλοντικός σου εαυτός θα σε ευγνωμονεί ...",
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/',
    },
    {
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://linkedin.com/',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:drkalaitzakis@yahoo.co.uk',
    },
    {
      icon: 'fa-mobile',
      name: 'Call on mobile',
      url: 'tel:+306947773003',
    },
    {
      icon: 'fa-phone',
      name: 'Call on phone',
      url: 'tel:+302810540140',
    },
  ],
};
