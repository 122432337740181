import React, { Component } from 'react';

export class EmailForm extends Component {
  constructor() {
    super();
    this.state = { message: '' };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ message: 'Ευχαριστώ για την επικοινωνία!' });
    setTimeout(() => {
      this.setState({ message: '' });
    }, 3000);
  }

  render() {
    const { message } = this.state;
    return (
      <form id="signup-form" onSubmit={this.onSubmit} method="post" action="#">
        <div className="signup-form--header">
          <input  type="text" name="fullname" id="fullname" placeholder="Ονοματεπώνυμο" required />
          <input type="email" name="email" id="email" placeholder="E-mail" required />
        </div>
        <div className="signup-form--text">
          <textarea placeholder="Το μήνυμά σου..." id="message" name="nessage" required></textarea>
        </div>
        <input type="submit" value="Αποστολή μηνύματος" />
        <span className={`${message ? 'visible success' : ''} message`}>
          {message}
        </span>
      </form>
    );
  }
}

export default EmailForm;
